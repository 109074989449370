const firebaseConfig = {
  apiKey: "AIzaSyBWNhOlBWVnCYY216HnjRbjXkDnacV-2vk",
  authDomain: "gutschein2go.firebaseapp.com",
  databaseURL: "https://gutschein2go.firebaseio.com",
  projectId: "gutschein2go",
  storageBucket: "gutschein2go.appspot.com",
  messagingSenderId: "879883641002",
  appId: "1:879883641002:web:d9511fbccb4e3f4063f0d3",
  measurementId: "G-K9ETVW3G1L",
};

export default firebaseConfig;

/**
 * SPDX-License-Identifier: (EUPL-1.2)
 * Copyright © 2019 Werbeagentur Christian Aichner
 */
